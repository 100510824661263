<template>
  <div id="login">
    <a-row type="flex" justify="space-around" align="middle">
      <a-col :span="8">
        <a-card title="Connexion">
          <h2>Votre connexion a réussi</h2>
          <p>Vous pouvez retourner sur l'onglet précédent</p>
          <a-button type="danger" @click="redirect"> J'ai fermé la fenêtre <a-icon type="smile" :rotate="180" /> </a-button>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "logged",
  methods: {
    redirect() {
      this.$router.push({
        name: "login",
      });
    }
  }
};
</script>

<style lang="less" scoped>
#login {
  height: 100vh;
  .ant-row-flex {
    height: 100%;
  }
}
</style>